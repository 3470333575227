import React, { useState, useContext, useEffect } from 'react';
import { Modal, Row, Col, Typography, message } from 'antd';

import { UserDataContext } from 'context/UserDataProvider';
import { PickrrButton } from 'components/UIElements';
import axios from 'axios';

const { Text } = Typography;

const ChargedAmountModal = ({ orderId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chargedData, setChargedData] = useState(null);
  const { authToken } = useContext(UserDataContext);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const getChargedWeight = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_PICKRR_V1}/order/get-invoice-amount-details/${orderId}/?auth_token=${authToken}`
    );
    if (response.data.success) {
      setChargedData(response.data.data);
    } else {
      message.error(response.data.err);
    }
  };

  const viewChargeWeight = () => {
    showModal();
    getChargedWeight();
  };
  return (
    <>
      <PickrrButton type="primary" onClick={viewChargeWeight}>
        View Charged Weight
      </PickrrButton>
      <Modal
        title="Charged Amount Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        {chargedData && (
          <>
            <Row>
              <Col span={24}>
                <h3>
                  {' '}
                  <b> Shipment Details</b>
                </h3>
              </Col>
              <Col span={12}>Dead Weight</Col>
              <Col span={12}>{chargedData.dead_weight}</Col>
              <Col span={12}>Dimensions</Col>
              <Col span={12}>
                {chargedData.length}x{chargedData.breadth}x{chargedData.height}{' '}
              </Col>
              <Col span={12}>Volumetric Divisor</Col>
              <Col span={12}>{chargedData.divisor}</Col>
              <Col span={12} style={{ borderBottom: '1px solid grey' }}>
                Volumetric Weight
              </Col>
              <Col span={12} style={{ borderBottom: '1px solid grey' }}>
                {chargedData.length}x{chargedData.breadth}x{chargedData.height}/
                {chargedData.divisor} ={' '}
                {(
                  (+chargedData.length *
                    +chargedData.breadth *
                    +chargedData.height) /
                  +chargedData.divisor
                ).toFixed(2)}
              </Col>

              <Col span={12} style={{ borderBottom: '1px solid grey' }}>
                <Text mark>
                  <b style={{ fontSize: '16px' }}> Charged Weight</b>
                </Text>
              </Col>
              <Col span={12} style={{ borderBottom: '1px solid grey' }}>
                <Text mark>
                  <b style={{ fontSize: '16px' }}>
                    {' '}
                    {chargedData.charged_weight}
                  </b>
                </Text>
              </Col>
            </Row>
            <Row style={{ marginTop: '12px' }}>
              <Col span={24}>
                <h3>
                  <b>Courier Selection</b>
                </h3>
              </Col>
              <Col span={12}>Courier</Col>
              <Col span={12}>{chargedData.courier_name}</Col>
              <Col span={12}>Zone</Col>
              <Col span={12}>{chargedData.zone}</Col>
              <Col span={12}>Weight Slab</Col>
              <Col span={12}>
                {chargedData.base_weight}kg + Additional{' '}
                {chargedData.additional_weight}
              </Col>
              <Col span={12}>Rate Card</Col>
              <Col span={12}>
                Rs{chargedData.base_price} + Rs{chargedData.additional_price}
              </Col>
              <Col span={12}>COD Charge</Col>
              <Col span={12}>{chargedData.cod_charge}</Col>
              <Col span={12}>Surcharge</Col>
              <Col span={12}>{chargedData.surcharge}</Col>
              {/* <Col span={12} style={{ borderBottom: '1px solid grey' }}>
                Tax
              </Col> */}
              {/* <Col span={12} style={{ borderBottom: '1px solid grey' }}>
                {'RS.20'}
              </Col> */}
            </Row>
            <Row>
              <Col span={12} style={{ borderBottom: '1px solid grey' }}>
                <Text mark>
                  {' '}
                  <b style={{ fontSize: '16px' }}>Charged Amount</b>
                </Text>
              </Col>
              <Col span={12} style={{ borderBottom: '1px solid grey' }}>
                <Text mark>
                  {' '}
                  <b style={{ fontSize: '16px' }}>
                    {+chargedData.base_price_multiplier}x
                    {+chargedData.base_price} +{' '}
                    {+chargedData.additional_price_multiplier}x
                    {+chargedData.additional_price} + {chargedData.cod_charge} +{' '}
                    {chargedData.surcharge} ={' '}
                    {+chargedData.base_price_multiplier *
                      +chargedData.base_price +
                      +chargedData.additional_price_multiplier *
                        +chargedData.additional_price +
                      +chargedData.cod_charge +
                      +chargedData.surcharge}
                  </b>
                </Text>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default ChargedAmountModal;
