import React, { useState, useContext } from 'react';
import { Row, Col, Select, DatePicker, message } from 'antd';
import axios from 'axios';

import { Placeholder } from './style';
import { FlexBox, PickrrButton } from 'components/UIElements';
import { UserDataContext } from 'context/UserDataProvider';

const { Option } = Select;
const { RangePicker } = DatePicker;

const DownloadByRangeForm = () => {
  const [courierList, setCourierList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [status, setStatus] = useState('transit');
  const [loading, setLoading] = useState(false);

  const { authToken, couriers: allCouriers, profileDetails } = useContext(
    UserDataContext
  );

  const handleDownload = async () => {
    setLoading(true);

    let couriers = '';
    courierList.forEach((cour, index) => {
      couriers += `${allCouriers[cour].join(',')}${
        index < courierList.length - 1 ? ',' : ''
      }`;
    });

    const response = await axios.get(
      `${process.env.REACT_APP_PICKRR_V1}${process.env.REACT_APP_DOWNLOAD_ORDERS_BY_DATE_RANGE}?is_courier_ops=true&auth_token=${authToken}&user_email=${profileDetails.email}&from_date=${fromDate}&to_date=${toDate}&date_type=placed&order_state=${status}&courier_keyword=${couriers}&values_list=order__order_id,tracking_id,order__client_order_id,order__placed_at,pickup_time,order__orderitems__product__name,order__dispatch_mode,order__cod_amount,order__invoice_value,order__courier_used,order__courier_tracking_id,fetch_current_status,fetch_current_status_datetime,fetch_current_status_update,order__drop_address__name,order__drop_address__phone_number,order__drop_address__pin_code,order__drop_address__state,order__pickup_address__name,order__pickup_address__phone_number,order__pickup_address__pin_code,order__pickup_address__address_line,fetch_out_for_delivery_count,order__vendor_id,order__orderitems__quantity,rto_waybill,collected_by,order__drop_address__address_line`
    );
    if (!response.data.err) {
      message.success('Requested report will be sent on Email!');
    } else {
      message.error(response.data.err);
    }
    setLoading(false);
  };

  return (
    <>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>Select Courier</Placeholder>
        </Col>
        <Col span={8}>
          <Select
            mode="multiple"
            showSearch
            style={{ border: '1px solid #B3BCE6', width: '100%' }}
            placeholder="Select Courier"
            value={courierList}
            onChange={(value) => setCourierList(value)}
          >
            {profileDetails &&
              profileDetails.couriers.map((cour) => (
                <Option value={cour} key={cour}>
                  {cour}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>Date Range</Placeholder>
        </Col>
        <Col span={8}>
          <RangePicker
            style={{ border: '1px solid #B3BCE6', width: '100%' }}
            onChange={(dates, dateStrings) => {
              dateStrings[0] ? setFromDate(dateStrings[0]) : setFromDate(null);
              dateStrings[1] ? setToDate(dateStrings[1]) : setToDate(null);
            }}
          />
        </Col>
      </Row>
      <Row gutter={24} align="middle" style={{ marginBottom: '42px' }}>
        <Col span={4}>
          <Placeholder>Status</Placeholder>
        </Col>
        <Col span={8}>
          <Select
            // mode="multiple"
            // allowClear
            style={{ width: '100%', border: '1px solid #B3BCE6' }}
            placeholder="Please select"
            value={status}
            onChange={(value) => setStatus(value)}
          >
            <Option value="not_picked">Not Picked</Option>
            <Option value="open">Open</Option>
            <Option value="ofd">OFD</Option>
            <Option value="transit">In Transit</Option>
            <Option value="delivered">Delivered</Option>
            <Option value="rto">RTO</Option>
            <Option value="rtd">RTD</Option>
            <Option value="all">All</Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={24} align="middle">
        <Col offset={4} span={8}>
          <FlexBox>
            {/* <PickrrButton color="outline" style={{ marginRight: '24px' }}>
              Cancel
            </PickrrButton> */}
            <PickrrButton
              color="primary"
              onClick={handleDownload}
              disabled={!fromDate || !toDate || !courierList.length || !status}
              loading={loading}
            >
              Download
            </PickrrButton>
          </FlexBox>
        </Col>
      </Row>
    </>
  );
};

export default DownloadByRangeForm;
