import React from 'react';

import { PickrrHeading, PickrrCard } from 'components/UIElements';
import UserForm from 'components/ManageUsers/AddUser/UserForm';

const AddUser = () => {
  return (
    <>
      <PickrrCard>
        <PickrrHeading className="mb-16">Add User</PickrrHeading>
        <UserForm />
      </PickrrCard>
    </>
  );
};

export default AddUser;
