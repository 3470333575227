import React, { useState, useContext, useEffect } from 'react';
import { Input, Checkbox, Row, Col, message } from 'antd';
import axios from 'axios';

import { Placeholder } from './style';
import {
  FlexBox,
  PickrrButton,
  CustomInput,
  CustomInputPassword,
} from 'components/UIElements';
import { UserDataContext } from 'context/UserDataProvider';
import { useLocation, useParams } from 'react-router-dom';

const UserForm = () => {
  const params = useParams();
  const location = useLocation();
  const [state, setState] = useState({
    seller_email: '',

    name: '',
    email: '',
    password: '',
    auth_token: '',
  });
  const [loading, setLoading] = useState(false);

  const { seller_email, name, email, password } = state;

  const { authToken } = useContext(UserDataContext);

  const handleAddUser = async () => {
    setLoading(true);
    try {
      const postData = {
        email: seller_email,
        dummy: {
          name,
          email,
          password,
          // auth_token: authToken,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_PICKRR_V1}/users/customer-success/create/`,
        postData
      );
      if (response.data.success) {
        message.success(response.data.message);
        setState({
          name: '',
          email: '',
          password: '',
        });
      } else {
        message.error(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const email = new URLSearchParams(location.search).get('seller_email');
    setState((s) => ({ ...s, seller_email: email }));
  }, [location.search]);

  return (
    <>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>
            Seller Email ID <span style={{ color: 'red' }}> *</span>
          </Placeholder>
        </Col>
        <Col span={8}>
          <CustomInput
            placeholder="Enter user email address"
            value={seller_email}
            onChange={(e) =>
              setState((s) => ({ ...s, seller_email: e.target.value }))
            }
          />
        </Col>
      </Row>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>
            Name <span style={{ color: 'red' }}> *</span>
          </Placeholder>
        </Col>
        <Col span={8}>
          <CustomInput
            placeholder="Enter user name"
            value={name}
            onChange={(e) => setState((s) => ({ ...s, name: e.target.value }))}
          />
        </Col>
      </Row>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>
            Email ID <span style={{ color: 'red' }}> *</span>
          </Placeholder>
        </Col>
        <Col span={8}>
          <CustomInput
            placeholder="Enter user email address"
            value={email}
            onChange={(e) => setState((s) => ({ ...s, email: e.target.value }))}
          />
        </Col>
      </Row>

      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>
            Password
            <span style={{ color: 'red' }}> *</span>
          </Placeholder>
        </Col>
        <Col span={8}>
          <CustomInputPassword
            value={password}
            onChange={(e) =>
              setState((s) => ({ ...s, password: e.target.value }))
            }
            placeholder="Enter user password"
          />
        </Col>
      </Row>

      <Row gutter={24} align="middle">
        <Col offset={4} span={8}>
          <FlexBox>
            <PickrrButton
              color="primary"
              disabled={!email || !name || !password}
              loading={loading}
              onClick={handleAddUser}
            >
              Add User
            </PickrrButton>
          </FlexBox>
        </Col>
      </Row>
    </>
  );
};

export default UserForm;
