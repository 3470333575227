import React, { useContext } from 'react';
import { message, Table } from 'antd';
import moment from 'moment';

import { Container, Tag } from './style';
import { SingleSearchOrdersContext } from 'context/SingleSearchOrdersProvider';
import ChargedAmountModal from 'components/ChargedAmountModal';
import { FlexBox, FlexColContainer, PickrrButton } from 'components/UIElements';
import axios from 'axios';

const DataTable = () => {
  const { loading, orders, searchOption } = useContext(
    SingleSearchOrdersContext
  );

  const syncTracking = async (trackingId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_PICKRR_V1}/api/tracking-manual-update/?tracking_id=${trackingId}`
    );
    if (response.data.success) {
      message.success('Tracing Synced');
    } else {
      message.error(response.data.err);
    }
    console.log(response);
  };

  const columns = [
    {
      title: 'Order Details',
      key: 'orderDetails',
      width: 150,
      render: (data) => (
        <FlexColContainer>
          <span style={{ fontWeight: 'bold' }}>Order ID</span>
          <span className="mb-16">{data.pickrr_order_id}</span>
          <span style={{ fontWeight: 'bold' }}>Tracking ID</span>
          <span>{data.pickrr_tracking_id}</span>
          <PickrrButton onClick={() => syncTracking(data.pickrr_tracking_id)}>
            Sync Tracking
          </PickrrButton>
        </FlexColContainer>
      ),
    },
    {
      title: 'Courier Details',
      dataIndex: 'courier_used',
      key: 'courier_used',
      width: 150,
    },
    {
      title: 'Status Update',
      key: 'statusUpdate',
      width: 200,
      render: (data) => (
        <FlexColContainer>
          <span style={{ fontWeight: 'bold' }}>Picked on</span>
          <span className="mb-16">
            {moment(data.pickup_date).format('DD/MM/YYYY')}
          </span>
          <span style={{ fontWeight: 'bold' }}>Placed on</span>
          <span className="mb-16">
            {moment(data.placed_date).format('DD/MM/YYYY')}
          </span>
          <span style={{ fontWeight: 'bold' }}>Current status - </span>
          <span>{data.current_status}</span>
          {!!data.edd_stamp && data.edd_stamp !== 'None' && (
            <>
              <span style={{ fontWeight: 'bold' }}>EDD</span>
              <span>{moment(data.edd_stamp).format('DD/MM/YYYY h:m')}</span>
            </>
          )}
          {!!data.promise_edd && data.promise_edd !== 'None' && (
            <>
              <span style={{ fontWeight: 'bold' }}>Promise EDD</span>
              <span>{moment(data.promise_edd).format('DD/MM/YYYY h:m')}</span>
            </>
          )}
        </FlexColContainer>
      ),
    },
    {
      title: 'Item Details',
      key: 'itemDetails',
      width: 200,
      render: (data) => (
        <FlexColContainer>
          <span className="mb-16">
            {data.item_name && data.item_name.join(', ')}
          </span>
          <FlexBox>
            <span style={{ fontWeight: 'bold' }}>Qty - </span>
            <span>{data.multiply_quantity}</span>
          </FlexBox>
        </FlexColContainer>
      ),
    },
    {
      title: 'Payment Details',
      key: 'paymentDetails',
      width: 150,
      render: (data) => (
        <FlexColContainer>
          <span style={{ fontWeight: 'bold' }} className="mb-16">
            ₹ {data.invoice_value.toFixed(2)}
          </span>
          <Tag>{data.cod_amount ? 'COD' : 'Prepaid'}</Tag>
          {!!data.cod_amount && (
            <>
              <span style={{ fontWeight: 'bold' }}>COD Value</span>
              <span>₹ {data.cod_amount.toFixed(2)}</span>
            </>
          )}
          <ChargedAmountModal orderId={data.pickrr_order_id} />
        </FlexColContainer>
      ),
    },
    {
      title: 'Customer Address',
      key: 'customerAddress',
      width: 200,
      render: (data) => (
        <FlexColContainer>
          <span>{data.drop_customer_name}</span>
          <span className="mb-16">{data.drop_customer_phone}</span>
          <span className="mb-16">
            {data.drop_address}, {data.drop_city} - {data.drop_state}
          </span>
          <span>
            Zone {data.order_zone} - {data.drop_pincode}
          </span>
        </FlexColContainer>
      ),
    },
    {
      title: 'Seller Email',
      key: 'seller_email',
      dataIndex: 'seller_email',
      width: 200,
    },
    {
      title: 'POC',
      key: 'poc',
      width: 200,
      render: (data) => (
        <FlexColContainer>
          <span style={{ fontWeight: 'bold' }}>Sales -</span>
          <span className="mb-16">{data.sales_poc}</span>
          <span style={{ fontWeight: 'bold' }}>Retention -</span>
          <span className="mb-16">{data.retention_poc}</span>
          <span style={{ fontWeight: 'bold' }}>Ops -</span>
          <span>{data.ops_poc}</span>
        </FlexColContainer>
      ),
    },
  ];

  const searchBySellerEmailColumns = [
    {
      title: 'Seller Email',
      key: 'seller_email',
      dataIndex: 'seller_email',
      width: 150,
    },
    {
      title: 'Sales POC',
      key: 'orderDetails',
      width: 150,
      render: (data) => (
        <FlexColContainer>
          <span>{data.sales_poc_name}</span>
          <span>{data.sales_poc}</span>
        </FlexColContainer>
      ),
    },
    {
      title: 'Retention POC',
      key: 'orderDetails',
      width: 150,
      render: (data) => (
        <FlexColContainer>
          <span>{data.retention_poc_name}</span>
          <span>{data.retention_poc}</span>
        </FlexColContainer>
      ),
    },
    {
      title: 'Ops POC',
      key: 'poc',
      width: 200,
      render: (data) => (
        <FlexColContainer>
          <span>{data.ops_poc_name}</span>
          <span>{data.ops_poc}</span>
        </FlexColContainer>
      ),
    },
  ];

  return (
    <Container>
      <Table
        columns={
          searchOption === 'email' ? searchBySellerEmailColumns : columns
        }
        dataSource={orders}
        rowKey={(record) => record.pickrr_tracking_id}
        pagination={false}
        loading={loading}
        scroll={{ x: 1000 }}
      />
    </Container>
  );
};

export default DataTable;
