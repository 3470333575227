import React from 'react';
import { Tabs } from 'antd';

import { PickrrHeading, PickrrCard } from 'components/UIElements';
import DownloadByRangeForm from 'components/DownloadOrders/DownloadByRangeForm';
import DownloadByIdForm from 'components/DownloadOrders/DownloadByIdForm';
import DownloadByPhoneNumber from 'components/DownloadOrders/DownloadByPhoneNumber';

const { TabPane } = Tabs;

const DownloadOrders = () => {
  return (
    <>
      <PickrrHeading className="mb-16">Download Orders</PickrrHeading>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Download by Date Range" key="1">
          <PickrrCard>
            <DownloadByRangeForm />
          </PickrrCard>
        </TabPane>
        <TabPane tab="Download by ID" key="2">
          <PickrrCard>
            <DownloadByIdForm />
          </PickrrCard>
        </TabPane>
        <TabPane tab="Download by Phone Number" key="3">
          <PickrrCard>
            <DownloadByPhoneNumber />
          </PickrrCard>
        </TabPane>
      </Tabs>
    </>
  );
};

export default DownloadOrders;
