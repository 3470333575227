import React, { createContext, useState, useEffect } from 'react';
import { message } from 'antd';
import axios from 'axios';

export const AllUsersDataContext = createContext();

const AllUsersDataProvider = ({ children }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [searchText, setSearchText] = useState('');

  const states = { allUsers, searchText };
  const functions = { setAllUsers, setSearchText };
  return (
    <AllUsersDataContext.Provider
      value={{
        ...states,
        ...functions,
      }}
    >
      {children}
    </AllUsersDataContext.Provider>
  );
};

export default AllUsersDataProvider;
