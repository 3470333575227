import React, { useState, useContext } from 'react';
import { Row, Col, Select, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

import { Placeholder } from './style';
import { FlexBox, PickrrButton } from 'components/UIElements';
import { UserDataContext } from 'context/UserDataProvider';

const { Option } = Select;

const DownloadByIdForm = () => {
  const [uploadType, setUploadType] = useState('tracking_id');
  const [uploadFile, setUploadFile] = useState([]);
  const [loading, setLoading] = useState(false);

  const { authToken, couriers: allCouriers, profileDetails } = useContext(
    UserDataContext
  );

  const uploadProps = {
    onRemove: (file) => {
      setUploadFile([]);
    },
    beforeUpload: (file) => {
      // if (file.type !== 'text/csv') {
      //   message.error(`${file.name} is not a csv file`);
      //   return Upload.LIST_IGNORE;
      // }
      setUploadFile([file]);
      return false;
    },
    uploadFile,
  };

  const handleDownload = async () => {
    setLoading(true);

    let couriers = '';
    profileDetails.couriers.forEach((cour, index) => {
      couriers += `${allCouriers[cour].join(',')}${
        index < profileDetails.couriers.length - 1 ? ',' : ''
      }`;
    });

    const formData = new FormData();
    formData.append('order_data', uploadFile[0]);
    formData.append('auth_token', authToken);
    formData.append('run_update_on_tracking_id', false);
    uploadType === 'tracking_id' &&
      formData.append('is_pickrr_tracking_id', true);
    // formData.append('is_pickrr_order_id', false);
    uploadType === 'client_order_id' &&
      formData.append('is_pickrr_client_order_id', true);
    formData.append('user_email', profileDetails.email);
    formData.append('is_courier_ops', true);
    formData.append('courier_keyword', couriers);
    formData.append('values_list', [
      'order__order_id',
      'tracking_id',
      'order__client_order_id',
      'order__placed_at',
      'pickup_time',
      'order__orderitems__product__name',
      'order__dispatch_mode',
      'order__cod_amount',
      'order__invoice_value',
      'order__courier_used',
      'order__courier_tracking_id',
      'fetch_current_status',
      'fetch_current_status_datetime',
      'fetch_current_status_update',
      'order__drop_address__name',
      'order__drop_address__phone_number',
      'order__drop_address__pin_code',
      'order__drop_address__state',
      'order__pickup_address__name',
      'order__pickup_address__phone_number',
      'order__pickup_address__pin_code',
      'order__pickup_address__address_line',
      'fetch_out_for_delivery_count',
      'order__vendor_id',
      'order__orderitems__quantity',
      'rto_waybill',
      'collected_by',
      'order__drop_address__address_line',
    ]);

    const response = await axios.post(
      `${process.env.REACT_APP_PICKRR_V1}${process.env.REACT_APP_DOWNLOAD_ORDERS_BY_ID}`,
      formData
    );
    if (!response.data.err) {
      message.success('Requested report will be sent on Email!');
      setUploadFile([]);
    } else {
      message.error(response.data.err);
    }
    setLoading(false);
  };

  return (
    <>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>Select Upload Type</Placeholder>
        </Col>
        <Col span={8}>
          <Select
            style={{ border: '1px solid #B3BCE6', width: '100%' }}
            value={uploadType}
            onChange={(value) => setUploadType(value)}
          >
            <Option value="tracking_id">Tracking ID</Option>
            <Option value="client_order_id">Client Order ID</Option>
            <Option value="courier_tracking_id">Courier Tracking ID</Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={24} align="middle" style={{ marginBottom: '42px' }}>
        <Col span={4}>
          <Placeholder>Upload</Placeholder>
        </Col>
        <Col span={8}>
          <Upload {...uploadProps} showUploadList={false}>
            <Button
              // icon={<UploadOutlined />}
              style={{
                border: '1px solid #B3BCE6',
                color: '#263f97',
              }}
            >
              {uploadFile.length ? (
                uploadFile[0].name.substr(0, 40)
              ) : (
                <>
                  <UploadOutlined /> Select csv file
                </>
              )}
            </Button>
          </Upload>
        </Col>
      </Row>
      <Row gutter={24} align="middle">
        <Col offset={4} span={8}>
          <FlexBox>
            {/* <PickrrButton color="outline" style={{ marginRight: '24px' }}>
              Cancel
            </PickrrButton> */}
            <PickrrButton
              color="primary"
              disabled={!uploadFile.length}
              loading={loading}
              onClick={handleDownload}
            >
              Download
            </PickrrButton>
          </FlexBox>
        </Col>
      </Row>
    </>
  );
};

export default DownloadByIdForm;
