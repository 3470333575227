import React from 'react';

import { PickrrHeading } from 'components/UIElements';
import DataTable from 'components/SingleSearchRecord/DataTable';

const SingleSearchRecord = () => {
  return (
    <>
      <PickrrHeading className="mb-16">Single Search Record</PickrrHeading>
      <div style={{ borderRadius: '15px', background: '#fff' }}>
        <div style={{ padding: '34px 24px 24px' }}>
          <PickrrHeading size={2}>Search result -</PickrrHeading>
        </div>
        <DataTable />
      </div>
    </>
  );
};

export default SingleSearchRecord;
