import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import ScrollToTop from "ScrollToTop";
import { PrivateRoute, AuthRoute } from "routes";
import AddUser from "pages/ManageUsers/AddUser";
import AllUsers from "pages/ManageUsers/AllUsers";
import EditUser from "pages/ManageUsers/EditUser";
import DownloadOrders from "pages/DownloadOrders";
import SingleSearchRecord from "pages/SingleSearchRecord";
import Login from "pages/Login";
import { UserDataProvider } from "context/UserDataProvider";
import { SingleSearchOrdersProvider } from "context/SingleSearchOrdersProvider";
import AllUsersDataProvider from "context/AllUsersDataProvider";
import CallRecord from "components/CallRecords";

function getFaviconEl() {
  return document.getElementById("favicon");
}
function App() {
  // const handleFavIcon = () => {
  //   const favicon = getFaviconEl();
  //   if (
  //     window.matchMedia &&
  //     window.matchMedia('(prefers-color-scheme: dark)').matches
  //   ) {
  //     favicon.href =
  //       'https://res.cloudinary.com/pickrr/image/upload/v1617194337/logo/White_i3pywj.svg';
  //   } else {
  //     favicon.href =
  //       'https://res.cloudinary.com/pickrr/image/upload/v1617194369/logo/Blue_tat2ry.svg';
  //   }
  // };
  // useEffect(() => {
  //   handleFavIcon();
  // }, []);

  return (
    <UserDataProvider>
      <SingleSearchOrdersProvider>
        <AllUsersDataProvider>
          <Router>
            <ScrollToTop>
              <Switch>
                <AuthRoute exact path="/" component={Login} />
                <PrivateRoute
                  exact
                  path="/single-search-record"
                  component={SingleSearchRecord}
                />
                <PrivateRoute
                  exact
                  path="/manage-users/add-user"
                  component={AddUser}
                />
                <PrivateRoute
                  exact
                  path="/manage-users/all-users"
                  component={AllUsers}
                />
                <PrivateRoute
                  exact
                  path="/call-records"
                  component={CallRecord}
                />
              </Switch>
            </ScrollToTop>
          </Router>
        </AllUsersDataProvider>
      </SingleSearchOrdersProvider>
    </UserDataProvider>
  );
}

export default App;
