import React, { createContext, useState, useEffect, useContext } from 'react';
import { message } from 'antd';
import axios from 'axios';

import { UserDataContext } from './UserDataProvider';

export const SingleSearchOrdersContext = createContext();

export const SingleSearchOrdersProvider = ({ children }) => {
  // const [searchOption, setSearchOption] = useState('tracking_id');
  // const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  // const [orders, setOrders] = useState([]);

  const {
    authToken,
    setSearchOption,
    setSearchValue,
    setOrders,
    searchOption,
    searchValue,
    orders,
  } = useContext(UserDataContext);

  const handleSearchOptionChange = (value) => setSearchOption(value);
  const handleSearchValueChange = (e) => setSearchValue(e.target.value);

  useEffect(() => {
    if (searchValue) {
      const handleSearch = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_PICKRR_V1}/sales-operations/internal-search/?auth_token=${authToken}&search_option=${searchOption}&search_key=${searchValue}`
          );
          if (response.data.status === 200) {
            setOrders(response.data.orders);
          } else {
            message.error(response.data.message);
          }
          setLoading(false);
        } catch (error) {
          message.error(error.message);
          setLoading(false);
        }
      };
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, authToken]);

  useEffect(() => {
    setOrders([]);
  }, [searchOption, setOrders]);

  return (
    <SingleSearchOrdersContext.Provider
      value={{
        searchOption,
        searchValue,
        loading,
        handleSearchOptionChange,
        handleSearchValueChange,
        orders,
      }}
    >
      {children}
    </SingleSearchOrdersContext.Provider>
  );
};
