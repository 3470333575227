import React, { createContext, useState, useEffect } from 'react';
import { message } from 'antd';
import axios from 'axios';

export const UserDataContext = createContext();

export const UserDataProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem('auth_token')
  );

  const [loading, setLoading] = useState(false);

  const [searchOption, setSearchOption] = useState('tracking_id');
  const [searchValue, setSearchValue] = useState('');
  const [orders, setOrders] = useState([]);

  const handleLogin = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PICKRR_V1}/sales-operations/helpdesk/login/`,
        data
      );
      if (response.data.success) {
        const { auth_token } = response.data;
        setAuthToken(auth_token);

        localStorage.setItem('auth_token', auth_token);
        // localStorage.setItem('is_admin', is_admin);
        // message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const handleLogout = () => {
    setAuthToken(null);

    setSearchOption('tracking_id');
    setSearchValue('');
    setOrders([]);
    localStorage.removeItem('auth_token');
  };

  return (
    <UserDataContext.Provider
      value={{
        authToken,

        loading,
        handleLogin,

        handleLogout,

        searchOption,
        setSearchOption,
        searchValue,
        setSearchValue,
        orders,
        setOrders,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
