import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import MasterLayout from './components/MasterLayout';
import { UserDataContext } from 'context/UserDataProvider';

export const AuthRoute = ({ component: Component, ...rest }) => {
  const { authToken } = useContext(UserDataContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        authToken ? (
          <Redirect
            to={{
              pathname: '/manage-users/all-users',
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authToken } = useContext(UserDataContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        authToken ? (
          <MasterLayout>
            <Component {...props} />
          </MasterLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  );
};
