import React, { useState, useContext } from 'react';
import { Input, Row, Col } from 'antd';

import { PickrrCard, PickrrButton } from 'components/UIElements';
import { Title, Placeholder } from './style';
import { UserDataContext } from 'context/UserDataProvider';

const LoginFormCard = () => {
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const { email, password } = state;

  const { loading, handleLogin } = useContext(UserDataContext);

  return (
    <PickrrCard style={{ minWidth: '502px' }}>
      <Title>Customer Success Login</Title>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={6}>
          <Placeholder>Email ID</Placeholder>
        </Col>
        <Col span={18}>
          <Input
            style={{ border: '1px solid #B3BCE6' }}
            value={email}
            onChange={(e) => setState((s) => ({ ...s, email: e.target.value }))}
            onPressEnter={() => handleLogin(state)}
          />
        </Col>
      </Row>
      <Row gutter={24} align="middle" style={{ marginBottom: '42px' }}>
        <Col span={6}>
          <Placeholder>Password</Placeholder>
        </Col>
        <Col span={18}>
          <Input.Password
            style={{ border: '1px solid #B3BCE6' }}
            value={password}
            onChange={(e) =>
              setState((s) => ({ ...s, password: e.target.value }))
            }
            onPressEnter={() => handleLogin(state)}
          />
        </Col>
      </Row>
      <PickrrButton
        color="primary"
        size="large"
        block
        disabled={!email || !password}
        loading={loading}
        onClick={() => handleLogin(state)}
      >
        Login
      </PickrrButton>
    </PickrrCard>
  );
};

export default LoginFormCard;
