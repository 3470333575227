import React, { useState, useContext } from 'react';
import { Row, Col, DatePicker, message, Upload, Button } from 'antd';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';

import { Placeholder } from './style';
import { FlexBox, PickrrButton } from 'components/UIElements';
import { UserDataContext } from 'context/UserDataProvider';

const { RangePicker } = DatePicker;

const DownloadByPhoneNumber = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);

  const { authToken } = useContext(UserDataContext);

  const uploadProps = {
    onRemove: (file) => {
      setUploadFile([]);
    },
    beforeUpload: (file) => {
      // if (file.type !== 'text/csv') {
      //   message.error(`${file.name} is not a csv file`);
      //   return Upload.LIST_IGNORE;
      // }
      setUploadFile([file]);
      return false;
    },
    uploadFile,
  };

  const handleDownload = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('order_data', uploadFile[0]);
    formData.append('auth_token', authToken);
    formData.append('start_date', fromDate);
    formData.append('end_date', toDate);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PICKRR_V1}${process.env.REACT_APP_DOWNLOAD_BY_PHONE_NUMBER_URL}`,
        formData
      );
      if (response.data.success) {
        message.success('You will receive the requested data on your mail id!');
        setUploadFile([]);
      } else {
        message.error(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>Date Range</Placeholder>
        </Col>
        <Col span={8}>
          <RangePicker
            style={{ border: '1px solid #B3BCE6', width: '100%' }}
            onChange={(dates, dateStrings) => {
              dateStrings[0] ? setFromDate(dateStrings[0]) : setFromDate(null);
              dateStrings[1] ? setToDate(dateStrings[1]) : setToDate(null);
            }}
          />
        </Col>
      </Row>
      <Row gutter={24} align="middle" style={{ marginBottom: '42px' }}>
        <Col span={4}>
          <Placeholder>Upload</Placeholder>
        </Col>
        <Col span={8}>
          <Upload {...uploadProps} showUploadList={false}>
            <Button
              // icon={<UploadOutlined />}
              style={{
                border: '1px solid #B3BCE6',
                color: '#263f97',
              }}
            >
              {uploadFile.length ? (
                uploadFile[0].name.substr(0, 40)
              ) : (
                <>
                  <UploadOutlined /> Select csv file
                </>
              )}
            </Button>
          </Upload>
        </Col>
      </Row>
      <Row gutter={24} align="middle">
        <Col offset={4} span={8}>
          <FlexBox>
            {/* <PickrrButton color="outline" style={{ marginRight: '24px' }}>
              Cancel
            </PickrrButton> */}
            <PickrrButton
              color="primary"
              onClick={handleDownload}
              disabled={!fromDate || !toDate || !uploadFile.length}
              loading={loading}
            >
              Download
            </PickrrButton>
          </FlexBox>
        </Col>
      </Row>
    </>
  );
};

export default DownloadByPhoneNumber;
