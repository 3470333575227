import React, { useContext, useEffect, useState } from "react";
import { Table, message } from "antd";

import { useHistory } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";

import { Container } from "./style";
import { FlexColContainer, PickrrButton } from "components/UIElements";

const DataTable = ({ user }) => {
  const history = useHistory();

  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "User Email ID",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Mobile Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "COD Gap",
      dataIndex: "cod_gap",
      key: "cod_gap",
    },
    {
      title: "COD Cycle",
      dataIndex: "cod_cycle",
      key: "cod_cycle",
    },
    {
      title: "Max Liability",
      dataIndex: "liability_amount",
      key: "liability_amount",
    },
    {
      title: "POC",
      key: "poc",
      width: 200,
      render: (data) => (
        <FlexColContainer>
          <span style={{ fontWeight: "bold" }}>Sales -</span>
          <span className="mb-16">{data.sales_poc}</span>
          <span style={{ fontWeight: "bold" }}>Retention -</span>
          <span className="mb-16">{data.retention_poc}</span>
          <span style={{ fontWeight: "bold" }}>Ops -</span>
          <span>{data.ops_poc}</span>
        </FlexColContainer>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record, data) => {
        return (
          <FlexColContainer>
            <PickrrButton
              onClick={() =>
                history.push(
                  `/manage-users/add-user/?seller_email=${record.email}`
                )
              }
            >
              Add User
            </PickrrButton>
            <PickrrButton
              disabled={!data.dummy_users.length}
              style={{ marginTop: "12px" }}
              onClick={() =>
                window.open(
                  `https://dashboard.pickrr.com/sign-in/?email=${data.dummy_users[0].email}&password=${data.dummy_users[0].password}`
                )
              }
            >
              Login
            </PickrrButton>
          </FlexColContainer>
        );
      },
    },
  ];

  return (
    <>
      <Container>
        {user.length > 0 && (
          <Table
            columns={columns}
            dataSource={user}
            rowKey={(record) => record.id}
            pagination={false}
            scroll={{ x: 1000 }}
          />
        )}
      </Container>
    </>
  );
};

export default DataTable;
