import React, { useState, useContext, useEffect } from 'react';
import { Input, Checkbox, Row, Col, message } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { Placeholder } from './style';
import { FlexBox, PickrrButton } from 'components/UIElements';
import { UserDataContext } from 'context/UserDataProvider';

const CheckboxGroup = Checkbox.Group;

const EditUserForm = () => {
  const history = useHistory();
  const [courierOptions, setCourierOptions] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [state, setState] = useState({
    name: '',
    email: '',
    phone_number: '',
    password: '',
    confirmPassword: '',
    id: '',
  });
  const [loading, setLoading] = useState(false);

  const { id, name, email, phone_number, password, confirmPassword } = state;

  const {
    authToken,
    couriers: allCouriers,
    editUserData,
    setEditUserData,
  } = useContext(UserDataContext);

  useEffect(() => {
    if (editUserData) {
      const {
        name,
        phone_number,
        email,
        id,
        couriers,
        password,
      } = editUserData;
      setState({
        name,
        phone_number,
        email,
        id,
        password,
        confirmPassword: password,
      });
      setCheckedList(couriers);
      setIndeterminate(
        !!couriers.length && couriers.length < courierOptions.length
      );
      setCheckAll(couriers.length === courierOptions.length);
    }
  }, [editUserData, courierOptions]);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < courierOptions.length);
    setCheckAll(list.length === courierOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? courierOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleEditUser = async () => {
    if (password !== confirmPassword) {
      message.error('Password and confirm password do not match!');
    } else {
      setLoading(true);
      try {
        const postData = {
          id,
          name,
          email,
          phone_number,
          password,
          couriers: checkedList,
          auth_token: authToken,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_PICKRR_V1}${process.env.REACT_APP_EDIT_USER_URL}`,
          postData
        );
        if (response.data.success) {
          message.success(response.data.message);
          setEditUserData(null);
          setState({
            id: '',
            name: '',
            email: '',
            phone_number: '',
            password: '',
            confirmPassword: '',
          });
          setCheckedList([]);
          setIndeterminate(false);
          setCheckAll(false);
          history.push('/manage-users/all-users');
        } else {
          message.error(response.data.message);
        }
        setLoading(false);
      } catch (error) {
        message.error(error.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setCourierOptions(Object.keys(allCouriers));
  }, [allCouriers]);

  return (
    <>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>User Name</Placeholder>
        </Col>
        <Col span={8}>
          <Input
            placeholder="Enter user name"
            style={{ border: '1px solid #B3BCE6' }}
            value={name}
            onChange={(e) => setState((s) => ({ ...s, name: e.target.value }))}
          />
        </Col>
      </Row>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>Email ID</Placeholder>
        </Col>
        <Col span={8}>
          <Input
            placeholder="Enter user email address"
            style={{ border: '1px solid #B3BCE6' }}
            value={email}
            onChange={(e) => setState((s) => ({ ...s, email: e.target.value }))}
          />
        </Col>
      </Row>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>Contact number</Placeholder>
        </Col>
        <Col span={8}>
          <Input
            placeholder="Enter user contact number"
            style={{ border: '1px solid #B3BCE6' }}
            value={phone_number}
            onChange={(e) =>
              setState((s) => ({ ...s, phone_number: e.target.value }))
            }
          />
        </Col>
      </Row>
      <Row gutter={24} align="middle" className="mb-24">
        <Col span={4}>
          <Placeholder>Password</Placeholder>
        </Col>
        <Col span={8}>
          <Input.Password
            style={{ border: '1px solid #B3BCE6' }}
            value={password}
            onChange={(e) =>
              setState((s) => ({ ...s, password: e.target.value }))
            }
          />
        </Col>
      </Row>
      <Row gutter={24} align="middle" style={{ marginBottom: '42px' }}>
        <Col span={4}>
          <Placeholder>Confirm Password</Placeholder>
        </Col>
        <Col span={8}>
          <Input.Password
            style={{ border: '1px solid #B3BCE6' }}
            value={confirmPassword}
            onChange={(e) =>
              setState((s) => ({ ...s, confirmPassword: e.target.value }))
            }
          />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: '42px' }}>
        <Col span={4}>
          <Placeholder>Assign Courier</Placeholder>
        </Col>
        <Col span={20}>
          <div
            style={{
              border: '1px solid #7E8FCA',
              borderRadius: '10px',
              padding: '24px',
            }}
          >
            {!!courierOptions.length && (
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                className="mb-24"
              >
                Select all
              </Checkbox>
            )}
            <CheckboxGroup value={checkedList} onChange={onChange}>
              <Row gutter={16}>
                {courierOptions.map((cour) => (
                  <Col span={6}>
                    <Checkbox value={cour} key={cour}>
                      {cour}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </CheckboxGroup>
          </div>
        </Col>
      </Row>
      <Row gutter={24} align="middle">
        <Col offset={4} span={8}>
          <FlexBox>
            <PickrrButton
              color="outline"
              style={{ marginRight: '24px' }}
              onClick={() => {
                setEditUserData(null);
                history.push('/manage-users/all-users');
              }}
            >
              Cancel
            </PickrrButton>
            <PickrrButton
              color="primary"
              disabled={
                !email ||
                !phone_number ||
                !password ||
                !confirmPassword ||
                !checkedList.length
              }
              loading={loading}
              onClick={handleEditUser}
            >
              Save
            </PickrrButton>
          </FlexBox>
        </Col>
      </Row>
    </>
  );
};

export default EditUserForm;
