import React, { useState } from "react";
import { Input, Modal, Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";

// import { FlexColContainer } from 'components/UIElements';
//import { downloadFile } from '../../utils';

const DownloadModal = ({
  visible,
  setVisible,
  type,
  searchDetails,
  setEmail,
  handleDownload,
  id,
  loader,
}) => {
  return (
    <Modal
      title={"Download Records"}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      centered
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "14px", color: "#263F97" }} className="mb-8">
          Email id
        </span>
        <Input
          placeholder="Enter Email id"
          className="mb-16"
          value={searchDetails.email}
          onChange={(e) =>
            setEmail({ ...searchDetails, email: e.target.value })
          }
        />
        <Button
          style={{
            borderRadius: "8px",
            borderColor: "#263F97",
            color: "#fff",
            background: "#263F97",
            marginTop: "24px",
          }}
          icon={<DownloadOutlined />}
          onClick={handleDownload}
          loading={loader}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default DownloadModal;
