import styled from 'styled-components';

export const Container = styled.div`
  .ant-table {
    background: #edf0f9;
  }
  .ant-table-thead > tr > th {
    background: #fff;
    color: #7d8cc1;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    /* text-transform: uppercase; */
    border-color: #edf0f9;
    border-width: 2px;
  }
  .ant-table-tbody > tr > td {
    background: #fff;
    word-wrap: break-word;
    word-break: break-all;
    border-color: #edf0f9;
    border-width: 2px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #263f97;
    text-align: center;
    border-radius: 5px;
  }
  .ant-table-tbody > tr {
    vertical-align: top;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f1faff;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ebedf7;
  cursor: pointer;
`;
