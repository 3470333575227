import React, { useState, useEffect, useContext } from "react";
import { Layout, Menu, Select, Input, Row, Col, Dropdown } from "antd";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  LogoutOutlined,
  SearchOutlined,
  UserOutlined,
  UserAddOutlined,
  UnorderedListOutlined,
  FileSearchOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import {
  MasterLayoutStyle,
  LogoContainer,
  ContentX,
  SelectContainer,
  SearchContainer,
} from "./style";
import { FlexContainer, FlexEndContainer } from "../UIElements";
import { UserDataContext } from "context/UserDataProvider";
import { SingleSearchOrdersContext } from "context/SingleSearchOrdersProvider";

const { Header, Footer, Sider } = Layout;
const { Option } = Select;

const MasterLayout = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [defaultSelectedKey, setDefaultSelectedKey] = useState("");

  const { handleLogout } = useContext(UserDataContext);
  const { searchOption, handleSearchOptionChange, handleSearchValueChange } =
    useContext(SingleSearchOrdersContext);

  useEffect(() => {
    setDefaultSelectedKey(location.pathname);
  }, [location.pathname]);

  const profileMenu = (
    <Menu style={{ padding: 4 }}>
      <Menu.Item onClick={handleLogout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <MasterLayoutStyle />
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          backgroundColor: "#263f97",
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <LogoContainer>
          {collapsed ? (
            <img
              src="https://res.cloudinary.com/pickrr/image/upload/v1616403457/Sales%20App/ws-logo_cseakc.svg"
              style={{
                height: "32px",
                margin: "16px 10px",
              }}
              alt="logo"
            />
          ) : (
            <img
              src="https://res.cloudinary.com/pickrr/image/upload/v1616403457/Sales%20App/w-logo_dgilt3.svg"
              style={{
                height: "32px",
                margin: "16px 10px",
              }}
              alt="logo"
            />
          )}
        </LogoContainer>
        <Menu
          theme="light"
          mode="inline"
          defaultOpenKeys={["manage-users"]}
          selectedKeys={[defaultSelectedKey]}
        >
          <Menu.Item
            key="/manage-users/all-users"
            icon={<UnorderedListOutlined style={{ color: "#FFF" }} />}
          >
            <Link to="/manage-users/all-users">All Users</Link>
          </Menu.Item>
          <Menu.Item
            key="/manage-users/add-user"
            icon={<UserAddOutlined style={{ color: "#FFF" }} />}
          >
            <Link to="/manage-users/add-user">Add User</Link>
          </Menu.Item>

          <Menu.Item
            key="/single-search-record"
            icon={<FileSearchOutlined style={{ color: "#FFF" }} />}
          >
            <Link to="/single-search-record">Search Records</Link>
          </Menu.Item>
          <Menu.Item
            key="/call-records"
            icon={<PhoneOutlined style={{ color: "#FFF" }} />}
          >
            <Link to="/call-records">Call Records</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed ? "80px" : "200px",
          transition: "all 0.2s",
        }}
      >
        {/* <IconContext.Provider
          value={{
            className: 'trigger',
            size: '18px',
            style: {
              verticalAlign: 'middle',
            },
          }}
        > */}
        <Header
          style={{
            padding: "0 24px",
            background: "#fff",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.06)",
          }}
        >
          {/* {React.createElement(
                collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
                {
                  onClick: toggle,
                }
              )} */}
          <Row gutter={16} style={{ height: "64px" }}>
            <Col span={6}>
              <SelectContainer>
                <Select
                  style={{ width: "100%" }}
                  onChange={handleSearchOptionChange}
                  value={searchOption}
                >
                  <Option value="tracking_id">Pickrr Tracking ID</Option>
                  <Option value="courier_tracking_id">
                    Courier Tracking ID
                  </Option>
                  <Option value="customer_phone_number">
                    Customer Phone number
                  </Option>
                  <Option value="customer_name">Customer Name</Option>
                  <Option value="email">Seller Email</Option>
                </Select>
              </SelectContainer>
            </Col>
            <Col span={10}>
              <SearchContainer>
                <Input
                  placeholder="Search single record"
                  prefix={<SearchOutlined />}
                  onPressEnter={(e) => {
                    history.push("/single-search-record");
                    handleSearchValueChange(e);
                  }}
                />
              </SearchContainer>
            </Col>
            <Col span={8}>
              <FlexEndContainer
                style={{ alignItems: "center", height: "64px" }}
              >
                <Dropdown overlay={profileMenu} placement="bottomRight" arrow>
                  <UserOutlined
                    style={{
                      color: "#263F97",
                      fontSize: "24px",
                      cursor: "pointer",
                    }}
                  />
                </Dropdown>
              </FlexEndContainer>
            </Col>
          </Row>

          <FlexContainer>
            {/* <Dropdown overlay={profileMenu} placement="bottomRight" arrow>
                  <NavLinks
                    style={{
                      paddingLeft: '24px',
                      borderLeft: '1px solid #DFE0EB',
                    }}
                  >
                    <span
                      style={{
                        marginRight: '5px',
                      }}
                    >
                      {walletDetails && walletDetails.name}
                    </span>
             
                  </NavLinks>
                </Dropdown> */}
          </FlexContainer>
        </Header>
        {/* </IconContext.Provider> */}
        <ContentX>{children}</ContentX>

        <Footer style={{ textAlign: "center" }}>
          Pickrr Technologies Pvt. Ltd. All Rights Reserved ©2020
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MasterLayout;
