import React, { useState, useContext } from 'react';
import { Input, message } from 'antd';
import axios from 'axios';

import { PickrrHeading } from 'components/UIElements';
import { Container } from './style';
import DataTable from 'components/ManageUsers/AllUsers/DataTable';
import { UserDataContext } from 'context/UserDataProvider';
import { AllUsersDataContext } from 'context/AllUsersDataProvider';

const { Search } = Input;

const AllUsers = () => {
  const [loading, setLoading] = useState(false);

  const { authToken } = useContext(UserDataContext);
  const { allUsers, setAllUsers, searchText, setSearchText } =
    useContext(AllUsersDataContext);

  const fetchUsers = (value) => {
    const fetchAllUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_PICKRR_V1
          }/users/customer-success/?auth_token=${authToken}&email=${encodeURIComponent(
            value
          )}`
        );
        if (response.data.success) {
          setAllUsers(response.data.users);
        } else {
          setAllUsers([]);
          message.error(response.data.message);
        }
        setLoading(false);
      } catch (error) {
        setAllUsers([]);
        message.error(error.message);
        setLoading(false);
      }
    };
    fetchAllUsers();
  };
  const searchOnChange = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <Container>
      <PickrrHeading className="mb-16">All Users</PickrrHeading>
      <Search
        placeholder="Enter user name to search"
        enterButton
        size="large"
        style={{ maxWidth: '350px' }}
        className="mb-16"
        allowClear
        onSearch={fetchUsers}
        onChange={searchOnChange}
        value={searchText}
      />
      <DataTable user={allUsers} />
    </Container>
  );
};

export default AllUsers;
