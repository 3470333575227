import React, { useState } from "react";

import { SaveFilled, UploadOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  notification,
  Upload,
  message,
  Button,
} from "antd";
import DownloadModal from "./Modal";
import { PickrrButton, FlexBox, PickrrHeading } from "components/UIElements";
import axios from "axios";

const { Option } = Select;

const CallRecord = () => {
  const [form] = Form.useForm();
  const [searchCsv, setSearchCsv] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [domKey, setDomKey] = useState(0);
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchDetails, setSearchDetails] = useState({
    keys: "",
    fields: "",
    email: "",
  });

  const uploadForSearch = {
    onRemove: (file) => {
      setSearchCsv([]);
    },
    beforeUpload: (file) => {
      if (file.name.split(".")[file.name.split(".").length - 1] !== "csv") {
        message.error("You can only upload CSV file!");
        return false;
      }
      setSearchCsv([file]);
      return false;
    },
    searchCsv,
  };

  const handleChanges = (value) => {
    if (value.keys) {
      setSearchDetails({ ...searchDetails, keys: value.keys });
    }
    if (value.data) {
      setSearchDetails({ ...searchDetails, data: value.data });
    }
    // setSearchDetails({[]})
    setSelectType(value.keys);
  };

  const onReset = () => {
    form.resetFields();
    setSearchCsv([]);
    setDomKey(Math.random());
  };

  const onFinish = async (value) => {
    if (searchDetails.keys === "awb_csv" && !searchCsv.length) {
      message.error("please upload a csv");
    } else {
      setLoader(true);
      const formData = new FormData();
      formData.append("search_key", searchDetails.keys);
      formData.append("email_id", searchDetails.email);
      formData.append("source_panel", "cs");
      formData.append("panel_auth_token", "Y3MgYWFsYSB0eWk=");
      if (searchDetails.keys !== "awb_csv") {
        formData.append("data", searchDetails.data);
      } else {
        formData.append("data", searchCsv[0]);
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PICKRR_NDR}/ndr_calling/filter-ndr-call-records/`,
          formData
        );
        if (response.data.success) {
          notification.success({ message: response.data.message, duration: 3 });
        }
        form.resetFields();
        setDomKey(Math.random());
        setLoader(false);
        setVisible(false);
        setSearchDetails({
          keys: "",
          fields: "",
          email: "",
        });
        // if (response.data.err) {
        // message.success({ message: 'success' });
        // }
      } catch (err) {
        setLoader(false);
        notification.error({
          message: err?.response?.data?.err || err.message,
        });
      }
    }
  };
  return (
    <>
      <PickrrHeading className="mb-16">Call Records</PickrrHeading>
      <Form
        form={form}
        layout="vertical"
        name="single_pod_detail"
        onFinish={() => setVisible(true)}
        autoComplete="off"
        onValuesChange={handleChanges}
      >
        <Row
          gutter={[20, 24]}
          style={{
            backgroundColor: "#fff",
            borderRadius: "12px",
            padding: "20px",
          }}
        >
          <Col xl={8} lg={8} md={8} sm={8} xs={24}>
            <Form.Item
              name="keys"
              label="Select Type"
              rules={[{ required: true, message: "Please select ype" }]}
            >
              <Select placeholder="select type">
                <Option value="awb_numbers">Tracking Id</Option>
                <Option value="awb_csv">Awb Csv</Option>
                <Option value="seller_names">Seller Name</Option>
                <Option value="courier_names">Courier Name</Option>
                {/* <Option value="call_centers">Call Center</Option> */}
              </Select>
            </Form.Item>
          </Col>
          {selectType !== "awb_csv" ? (
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Form.Item
                name="data"
                label="Entries"
                style={{ marginBottom: "5px" }}
                rules={[{ required: true, message: "Please enter data" }]}
              >
                <Input placeholder="Enter data" />
              </Form.Item>
              <p
                style={{
                  color: "gray",
                  fontSize: "13px",
                  opacity: "0.5",
                }}
              >
                <sup>*</sup>Use comma for multiple entries
              </p>
            </Col>
          ) : (
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <FlexBox style={{ paddingTop: 32 }} key={domKey}>
                <Upload {...uploadForSearch} maxCount={1} maxCount={1}>
                  <Button style={{ marginRight: "8px" }}>
                    <UploadOutlined />
                    Select CSV
                  </Button>
                </Upload>
              </FlexBox>
            </Col>
          )}
          <Col xl={6} lg={6} md={6} sm={6} xs={24}>
            <FlexBox style={{ paddingTop: 32 }}>
              <PickrrButton
                color="secondary"
                onClick={onReset}
                style={{ marginRight: "16px" }}
              >
                Reset
              </PickrrButton>
              <PickrrButton
                color="primary"
                htmlType="submit"
                icon={<SaveFilled />}
                // loading={loader}
              >
                Download
              </PickrrButton>
            </FlexBox>
          </Col>
        </Row>
      </Form>
      <DownloadModal
        visible={visible}
        setVisible={setVisible}
        searchDetails={searchDetails}
        setEmail={setSearchDetails}
        handleDownload={onFinish}
        loader={loader}
      />
    </>
  );
};

export default CallRecord;
